@font-face {
	font-family: "SangBleu Kingdom";
	src: url("./../../public/fonts/SangBleuKingdom/Regular.otf") format("opentype");
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: "SangBleu Kingdom";
	src: url("./../../public/fonts/SangBleuKingdom/Regular_Italic.otf") format("opentype");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "SangBleu Kingdom";
	src: url("./../../public/fonts/SangBleuKingdom/Medium.otf") format("opentype");
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "ITC Blair";
	src: url("./../../public/fonts/ITC_Blair/Medium.ttf") format("truetype");
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std";
	src: url("./../../public/fonts/Circular/Medium.otf") format("opentype");
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: "Circular Std";
	src: url("./../../public/fonts/Circular/Book.otf") format("opentype");
	font-weight: 450;
	font-display: swap;
}